/* toggler iconm style */
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
.logoHeading{

    font-family: 'Oswald', sans-serif;
    letter-spacing: .3px;
    color: black;
    font-size: 24px;
    font-weight: 800;
 
   
}
.title{
    font-family: 'Caveat', cursive;
    line-height: 0.5px;
    font-size: 18px ;
    color: rgb(255, 255, 255);
    text-transform: capitalize;

}
.show_icon svg, .blog_icon{
    width: 30px;
    height: 30px;
  margin-left:-20px;
  fill: #ea1d1d;
};



.blog_icon svg{


}