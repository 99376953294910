.deleteIcon svg{

    fill: red;
    font-size: 20px;
    cursor: pointer;
}

.display-images-container{

     width:250px;
     height:200px;
}
.display-images-container img{

     width:150px;
     height:150px;
     margin:0 auto;
}