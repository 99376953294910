.home-product-heading {
  font-weight: 600;
  font-size: 1.6em;
  line-height: 1.4;
  color: #415161;
}

.home-product-container {
  width: 200px;

  /* background-color: red; */
  height: auto;
}
.home-pd-img-container {
  overflow: hidden;
}
.home-product-container img {
  width: 100%;
}

.home-product-container:hover {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.home-product-container img:hover {
  transform: scale(1.3);
  transition: transform 0.4s ease-in-out;
}
.pd-name {
  color: var(--primary);
  font-weight: 500;
}
