.product-category-list li{
   cursor:pointer;
   margin:15px 5px ;
   font-size:14px;
    padding: 4px 0;
    color: #495057;
} 
.product-category-list svg{
    margin-top: -4px !important;
} 

.productCatagoryCard{

  
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #f6f6f6;
    border-radius: .25rem;
    margin-bottom: 24px;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

 .products-page{

    background-color:#eff2f7;
    
 }

 .category-title{

     color:var(--primary);
     font-weight:700;
 }

 .custom-select {

    border: 1px solid #ffffff !important;
 }
 .page-title-product {
    text-transform: uppercase;
    color:var(--primary);
    font-weight: 600;
    font-size: 16px!important;
}

.active-category{

     color:red !important;
}