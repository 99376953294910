.seriesFolderEditeIcon{

     color: blue;
}


.product-delete-card-container{
  
   
      width:200px;
      height:230px;
}

.product-delete-card-container img{

     width:100px;
     height:100px;
     
}
.product-delete-card-container p{

    font-size:14px;
    
     
}