.animate-border {
  position: relative;
  display: block;
  width: 115px;
  height: 3px;
  background: #007bff;
}

.animate-border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 0;
  bottom: 0;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  -webkit-animation: animborder 2s linear infinite;
  animation: animborder 2s linear infinite;
}

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

@keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

.animate-border.border-white:after {
  border-color: #fff;
}

.animate-border.border-yellow:after {
  border-color: #f5b02e;
}

.animate-border.border-orange:after {
  border-right-color: #007bff;
  border-left-color: #007bff;
}

.animate-border.border-ash:after {
  border-right-color: #eef0ef;
  border-left-color: #eef0ef;
}

.animate-border.border-offwhite:after {
  border-right-color: #f7f9f8;
  border-left-color: #f7f9f8;
}

/* Animated heading border */
@keyframes primary-short {
  0% {
    width: 15%;
  }
  50% {
    width: 90%;
  }
  100% {
    width: 10%;
  }
}

@keyframes primary-long {
  0% {
    width: 80%;
  }
  50% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

.footer-area{
    background: #f6f6f6;
}
.footer-area li {
  padding-bottom: 10px;
}

.social-media .icon {
  height: 40px;
  width: 40px;
  color: #1cc7c1;
  border-radius: 50%;
  border: 1px solid #6acece;
  padding: 10px;
}
.social-media .active-icon {
  background-color: var(--brand-color);
  color: white;
}
.social-media .icon:hover {
  background-color: var(--brand-color);
  color: white;
}
.footer-brand-title{

   color:var(--primary);

   font-size: 24px;
   font-weight: 800;
}

.footer-p{

   color: gray;
   font-size: 14px;
}

.footer-h{

  color:var(--primary);
  text-transform: uppercase;

  font-size: 16px;
  font-weight: 800;
}
#footer-Menu-items-wrapper li>a{

   text-decoration: none !important;
   color: gray;
   font-size: 16px;
}
.footer-address{

  font-size: 15px;
  color:var(--primary);
}
.footer-phoneNumber{
  text-decoration: none !important;
  color: var(--secondary);
  font-size:15px;
}
.footer-fb-icon svg{

  font-size: 37px;
  margin-top:20px;
}
.copy-right-text p{

   font-size: 15px;
}