
.about-container{
  height: 300px;
    background-attachment: fixed;
    background-image: url("./img/aboutBg1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.relative{

  position: relative;
}
.absolute{

   position: absolute;
}
.background-about-overlay{

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
background-color: rgb(128 62 62 / 71%);
  position: absolute;
}
.content-wrapper{
  
   height: 100%;
}
.content-wrapper div{
  
  height: 100%;
}
.background-overlay-heading{
  
 opacity:1;
 z-index:111111111111;
 text-transform: uppercase;
 color:white;
 font-family: var(--heading);
 letter-spacing: 10px;
}
.about-details-container p{
  font:normal 18px/22px var(--paragraph);
  line-height: 34px;
   color:#555;
   word-spacing: 2px;
   
}


