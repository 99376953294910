#fetureBrand {
  padding: 20px 10px;
  background-color: white;
}
#fetureBrand h3 {
  font-weight: 800;
  font-size: 1.5em;
  line-height: 1.4;
  color: #415161;
}
.brand-images-container {
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
}
.brand-images-container div {
  width: 150px;
  /* display: flex;
  justify-content: center; */
  margin-bottom: 10px;
}
.brand-images-container img {
  width: 130px;
  cursor: pointer;
  opacity: 0.6;
}
.brand-images-container img:hover {
  opacity: 1;
}

@keyframes drip {
  to {
    top: 190px;
  }
}
.brand-images-wrapper{
   width:200px;
   height:200px;
}
.brand-images-wrapper img{
  width:150px;
  
}
.cursor{
  cursor:pointer;
}


@media (max-width: 900px) {
  .brand-images-wrapper {
    width: 150px;
  }
  .brand-images-wrapper img {
    width: 120px;
  }
}

@media (max-width: 600px) {
  .brand-images-wrapper {
    width: 100px;
  }
  .brand-images-wrapper img {
    width: 80px;
  }
}
.sub-brand-text {
  color: #ff5151;
}
.border-bottom-effect {
  position: relative;
}
.border-bottom-effect:before {
  content: "";
  position: absolute;
  width: 102px;
  height: 3px;
  top: 62px;
  left: auto;

  z-index: 1;

  background-image: linear-gradient(326deg, #272822 0%, #da2d2d 74%);
}
