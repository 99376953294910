/*    Header     */
/*****************/
.header {
	position: relative;
	overflow: hidden;
	padding-top: 6rem;
	padding-bottom: 7rem;
	background: #e4e4e4;
}

.header .text-container {
	margin-bottom: 4rem;
	text-align: center;
}

.header .h1-large {
	margin-bottom: 1.375rem;
	color: #ffffff;
}

.header .p-large {
	margin-bottom: 2rem;
	color: #ffffff;
}

.header .ocean { 
	height: 2%;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background: #ffffff;
}

.header .wave {
	background: url('./Resource/img/wave.svg') repeat-x; 
	position: absolute;
	top: -190px;
	width: 6400px;
	height: 198px;
	animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
	transform: translate3d(0, 0, 0);
}

.header .wave:nth-of-type(2) {
	top: -170px;
	animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
	opacity: 1;
}
  
/* Wave Animation */
@keyframes wave {
	0% {
		margin-left: 0;
	}
	100% {
		margin-left: -1600px;
	}
}

@keyframes swell {
	0%, 100% {
		transform: translate3d(0,-25px,0);
	}
	50% {
		transform: translate3d(0,5px,0);
	}
}
/* end of wave animation */

.title{
	 color:   #ff5151;
	 font-weight: 800;
}
.btn-solid-lg {
	display: inline-block;
	border-radius: 5px;
    padding-top: 13px;
    padding-right: 40px;
    padding-bottom: 13px;
    padding-left: 40px;
	transition: all 0.2s;
	background-color: #ff5151;
	font-family: inherit;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
}
.btn-solid-lg svg{

	 margin-left: 5px;
}
.services-container-intro svg{

	 color: #ff5151;
	 margin-left:10px;

	 
}
.services-container-intro span{
	color:white;
}

/* services section  start  */

.services-image-container {

	width:100%;
	height:100%
}
.services-image-container img{

 width:100%;
 max-height: 324px;
}
.service-heading-container h4{

	font-weight: 600;
    font-size: 1.2em;
    line-height: 1.4;
    color: #415161;
	 
}

/* Slider start  */
.slider-container {


		max-height: 100vh !important;
		min-height:60vh
	
		

	

	
}
.slider-container img{

	 height:100%;
	 width:100%;

	
}
.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
    max-height: 90vh !important;
    min-height: 60vh !important;
}
.carousel .slider-wrapper {
	max-height:100vh !important;
	min-height:60vh !important;
}
.carousel .control-dots {
	top:50% !important;
}
.carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 25px;
    bottom: 137px !important;
    margin-top: 0;
    padding: 5px;
}