
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
:root{

    /*      Theme colors        */
    --primary: #415161;
    --secondary : #ff5151;
    --text-primary: #4d5156;
    --white: #ffffff;
  
  

    /* gradient color   */
    --background: linear-gradient(120deg, #78a0e1 0%, #56b75ec7 100%);

    /*      theme font-family   */
    --heading: 'Oswald', sans-serif;
    --paragraph: 'Open Sans', sans-serif;
    --Josefin : 'Josefin', cursive;
    --Lexend: 'Lexend', cursive;
    --Livvic : 'Livvic', cursive;
}
